@font-face {
  font-family: 'Aller Bold';
  src: local('Aller Bold'), url(./assets/fonts/Aller_Bd.ttf) format('truetype');
}
@font-face {
  font-family: 'Aller';
  src: local('Aller'), url(./assets/fonts/Aller_Rg.ttf) format('truetype');
}
/* GLOBAL */
body {
  margin: 0;
  font-family: 'Aller',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
li, p {
  font-family: 'Aller'
}
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
} 
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc; 
}
/* CLASSES */
.heightPage {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.myMenu {
	background: #fff;
	box-shadow: 1px 0 2px 1px rgba(0,0,0,0.3);
  z-index: 3;
}
.myContent {
  background: #f5f5f5;
  z-index: 1;
}
.myResponses {
  background: #fff;
  box-shadow: 1px 0 2px 1px rgba(0,0,0,0.3);
  z-index: 2;
}
.userResponses {
  background: #fff;
  z-index: 1;
}
.selection,
.nps {
  padding: 16px !important;
  width: 90%;
}
.selection {
	padding-bottom: 0px !important;
}
.MuiTypography-root {
  font-family: 'Aller' !important;
}
.MuiListItemIcon-root {
  min-width: 36px !important
}
.badge {
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
}
.responseTitle {
  font-size: 18px;
  font-family: 'Aller Bold';
  margin: 0;
}
.responseUser {
  margin: 0 0 10px;
}